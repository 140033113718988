function Links(api) {

  const create = data => {

    return api.$http({
      method: 'POST',
      url: `${api.host}/link/create`,
      data: data
    })
    .then(res => {
      return res.data
    }).catch(err => {
      throw err
    })
  }

  const get = id => {
    return api.$http({
      method: 'GET',
      url: `${api.host}/link/${id}`
    })
    .then(res => {
      return res.data
    }).catch(err => {
      throw err
    })
  }

  const proceedas = (token_id, data) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/private/proceed-as`,
      data: {token_id, ...data}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const newProceedas = (token_id, data) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/private/new-proceed-as`,
      data: {token_id, ...data}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const checkout = (token_id, price_id) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/checkout`,
      data: {token_id, price_id}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const addDoc = (formData) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/add-doc`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const checkPhone = (phone) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/phone-check`,
      data: {phone}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const validatePhone = (token_id, data) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/validate-phone`,
      data: {token_id, ...data}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const searchCompany = (data) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/oc/search-company`,
      data: data
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const addCompany = (token_id, data) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/oc/add-company`,
      data: {token_id, ...data}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const uploadLEDocs = formData => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/upload-le-docs`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const uploadRequestedLEDocs = formData => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/upload-requested-le-doc`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const addNewDir = (token_id, data) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/oc/add-dir`,
      data: {token_id, ...data}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const addNewUbo = (token_id, data) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/oc/add-ubo`,
      data: {token_id, ...data}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const addCompanyResource = (token_id, data) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/update-company-resource`,
      data: {token_id, ...data}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const getAppStatus = (token_id) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/applicant-status`,
      data: {token_id}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const createReport = token_id => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/reports`,
      data: {token_id}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const createBusinessReport = token_id => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/create-business-report`,
      data: {token_id}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const confirmInfo = (token_id) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/confirm-info`,
      data: {token_id}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const createUserAuto = (token_id) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/create-user-auto`,
      data: {token_id}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const confirmCre = (token_id, body_data) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/confirm-cre`,
      data: {token_id, ...body_data}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const uploadProfilePic = formData => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/upload-profile-pic`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const getLatestStatus = app_id => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/get-latest-status`,
      data: {app_id}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const addDocAndRecheck = (formData) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/ss/add-doc-re-check`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" }
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const getInfoByUserName = username => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/api/onboarding/checkunamele`,
      data: {username}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const moveToCheck = app_id => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/move-to-check`,
      data: {applicantId: app_id}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  const sendEmail = (token_id, data) => {
    return api.$http({
      method: 'POST',
      url: `${api.host}/link/send-email`,
      data: {token_id,...data}
    })
    .then(res => {
      return res.data
    })
    .catch(err => {
      throw err
    })
  }

  return {
    create,
    get,
    proceedas,
    newProceedas,
    checkout,
    addDoc,
    checkPhone,
    validatePhone,
    searchCompany,
    addCompany,
    uploadLEDocs,
    uploadRequestedLEDocs,
    addNewDir,
    addNewUbo,
    addCompanyResource,
    uploadProfilePic,
    getAppStatus,
    createReport,
    createBusinessReport,
    confirmInfo,
    confirmCre,
    getLatestStatus,
    addDocAndRecheck,
    getInfoByUserName,
    moveToCheck,
    sendEmail,
    createUserAuto
  }
}

export default Links
<template>
  <div id="q-app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'App',
  created: function () {

    // API might emit this if the request fails.
    this.$api.on('disconnected', () => {
      this.$router.push(this.$router.currentRoute.path+'/disconnected').catch(() => {})
    })
  },
}
</script>

<style lang="scss">
#q-app {
  font-family: 'Nunito', sans-serif;
  font-size: .875rem;
  @media (min-width: $breakpoint-sm-min) {
    font-size: 1rem;
  }
  font-weight: 300;
}
</style>